import classnames from 'classnames';
import { Fragment, type FunctionComponent } from 'react';

import { applyThousandSeparator } from './utils/applyThousandSeparator';
import { getCurrencySymbol, type Currency } from './utils/currency';
import { extractValues } from './utils/extractValues';

import styles from './PriceDeprecated.module.scss';

export type { Currency };
export type PriceValue = string | number;
export type Tax = 'VAT' | 'withoutVAT';

export interface PriceDeprecatedProps {
  className?: string;
  currency: Currency;
  currencyEnd?: boolean;
  customThousandSeparator?: string;
  stripEmptyDecimalValue?: boolean;
  supDecimal?: boolean;
  supTax?: boolean;
  tax?: Tax;
  taxSmallSize?: boolean;
  translations?: { VAT?: string; withoutVAT?: string };
  unit?: string;
  value: PriceValue;
}

const thousandSeparators: Record<Currency, string> = {
  EURO: ' ',
  POUND_STERLING: ',',
};

/**
 * The Price component display basic price with the currency. You can use props
 * to change the currency and toggle sup decimal.
 *
 * @deprecated Please use @/core/tamagoshi/Price instead.
 */
export const PriceDeprecated: FunctionComponent<PriceDeprecatedProps> = ({
  className,
  currency,
  currencyEnd = false,
  customThousandSeparator,
  stripEmptyDecimalValue = true,
  supDecimal = false,
  supTax = false,
  tax,
  taxSmallSize = false,
  translations,
  unit,
  value,
  ...otherProps
}) => {
  const rootClassnames = classnames(
    styles.root,
    {
      [styles.euro]: currency === 'EURO',
      [styles.poundSterling]: currency === 'POUND_STERLING',
      [styles.currencyEnd]: currencyEnd && currency === 'EURO',
    },
    className,
  );
  const taxClassnames = classnames(styles.tax, {
    [styles.taxSmallSize]: !supDecimal && taxSmallSize,
  });

  const Decimal = supDecimal && currency === 'EURO' ? 'sup' : Fragment;
  const Tax = supTax ? 'sup' : 'span';
  const { integer, decimal } = extractValues(value, stripEmptyDecimalValue);

  return (
    <span {...otherProps} className={rootClassnames}>
      <span className={styles.integer}>
        {applyThousandSeparator(
          integer,
          customThousandSeparator ?? thousandSeparators[currency],
        )}
      </span>
      <Decimal>
        <span className={styles.currency} data-testid="price-currency-symbol">
          {getCurrencySymbol(currency)}
        </span>
        <span className={styles.decimal}>{decimal}</span>
        {tax && translations?.[tax] && (
          <Tax className={taxClassnames}>{translations[tax]}</Tax>
        )}
      </Decimal>
      {unit && <span className={styles.unit}>&nbsp;/&nbsp;{unit}</span>}
    </span>
  );
};

PriceDeprecated.displayName = 'PriceDeprecated';
