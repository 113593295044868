import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { ManomanoNutLogo } from '@/core/tamagoshi/Logos/ManomanoNutLogo/ManomanoNutLogo';
import { ManomanoNutLogoB2B } from '@/core/tamagoshi/Logos/ManomanoNutLogoB2B/ManomanoNutLogoB2B';
import { Text } from '@/core/tamagoshi/Text/Text';

import styles from './FreeFastDeliveryByMMLabel.module.scss';

export interface FreeFastDeliveryByMMLabelProps {
  className?: string;
  label: string;
  isPro?: boolean;
}

export const FreeFastDeliveryByMMLabel: FunctionComponent<
  FreeFastDeliveryByMMLabelProps
> = ({ className, label, isPro = false }) => {
  const rootClassNames = classnames(styles.root, className);
  const Icon = isPro ? ManomanoNutLogoB2B : ManomanoNutLogo;

  return (
    <Text
      data-testid="free-fast-delivery-by-mm-label"
      className={rootClassNames}
      type="small"
      weight="semiBold"
      tag="span"
    >
      <Icon className={styles.logo} width="16px" height="16px" />
      <span>{label}</span>
    </Text>
  );
};

FreeFastDeliveryByMMLabel.displayName = 'FreeFastDeliveryByMMLabel';
