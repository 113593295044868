import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { ManomanoNutLogo } from '@/core/tamagoshi/Logos/ManomanoNutLogo/ManomanoNutLogo';
import { ManomanoNutLogoB2B } from '@/core/tamagoshi/Logos/ManomanoNutLogoB2B/ManomanoNutLogoB2B';

import { Text } from '../Text/Text';

import styles from './FastDeliveryByMMLabel.module.scss';

export interface FastDeliveryByMMLabelProps {
  className?: string;
  label: string;
  isPro?: boolean;
}

export const FastDeliveryByMMLabel: FunctionComponent<
  FastDeliveryByMMLabelProps
> = ({ className, label, isPro = false }) => {
  const rootClassNames = classnames(styles.root, className);
  const Icon = isPro ? ManomanoNutLogoB2B : ManomanoNutLogo;

  return (
    <Text
      data-testid="fast-delivery-by-mm-label"
      className={rootClassNames}
      type="small"
      weight="semiBold"
      tag="span"
    >
      <span>{label}</span>
      <Icon className={styles.logo} width="16px" height="16px" />
    </Text>
  );
};

FastDeliveryByMMLabel.displayName = 'FastDeliveryByMMLabel';
