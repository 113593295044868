import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import {
  type Currency,
  type PriceValue,
  type Tax,
} from '@/core/tamagoshi/deprecated/PriceDeprecated/PriceDeprecated';

import { Main } from './components/Main/Main';
import { Pro } from './components/Pro';
import { Retail } from './components/Retail/Retail';
import { Secondary } from './components/Secondary/Secondary';

import styles from './DetailedPrice.module.scss';

export interface DetailedPriceTranslations {
  price?: {
    VAT: string;
    withoutVAT: string;
  };
}

/**
 * The detailed price component can show a simple price like
 * [Price](?path=/docs/components-price--default) component. It can display
 * details like retail, without VAT, price by unit and other stuff
 * No matter how many elements it is made of, its height does not change
 *
 * /!\ WARNING: measurement price, price per unit  are not implemented
 *
 * /!\ WARNING: if data is sent through props, then it will be displayed
 */
export interface DetailedPriceProps {
  /**
   * price.VAT: 'price' props is displayed all taxes included, used for b2c
   * purposes
   *
   * price.withoutVAT: it takes 'price' props as without VAT price and
   * 'secondaryPrice' prop as VAT price, used for b2b purposes
   *
   * Translations used from ProductCardTranslations:
   * price: {
   *     withoutVAT: string;
   *     VAT: string;
   * };
   */
  translations?: DetailedPriceTranslations;
  /**
   * Root element className
   */
  className?: string;
  /**
   * Main price in general use cases
   * Price without including VAT when secondaryValue is defined
   */
  value: PriceValue;
  /**
   * Price before discount
   * This price is displayed strikethrough
   */
  retailValue?: number;
  /**
   * The currency
   */
  currency?: Currency;
  /**
   * Price including VAT when tax=withoutVAT
   * Else price by unity if the quantity is relevant. It can be by liter l for a
   * paint can
   */
  secondaryValue?: PriceValue;
  /**
   * Unit to display for the secondary value
   * This unit is displayed after the price and tax informations and separated by a slash
   */
  secondaryUnit?: string;
  /**
   * If false : 'retailValue', a strikethought price, is displayed inline with
   * the Main price
   *
   * Else if true : 'retailValue', a strikethought price, is displayed above the
   * Main price
   */
  retailAbove?: boolean;
  /**
   * Price shown with or without including VAT
   * If it's 'withoutVAT' it takes `price` and `retail` props as without VAT
   * price and `secondaryPrice` prop as VAT price
   *
   * Else if it is 'VAT', `price` and `retail` props is displayed all taxes
   * included and `secondaryPrice` prop as without VAT price
   *
   * Undefined to show no tax label
   */
  tax?: 'withoutVAT' | 'VAT';
  /**
   * Price displays Pro label
   */
  proLabel?: boolean;
  /**
   * By default, there is 3 lines. One before the main price, one to the main
   * price and one after. If there is no values to these lines, there is a
   * placeholder to keep the space.
   * This props disabled it.
   */
  placeholderDisabled?: boolean;
}

const invertedTaxMap: Record<Tax, Tax> = {
  withoutVAT: 'VAT',
  VAT: 'withoutVAT',
};

export const DetailedPrice: FunctionComponent<DetailedPriceProps> = ({
  translations = {
    price: {
      VAT: 'TTC',
      withoutVAT: 'HT',
    },
  },
  className,
  value,
  currency = 'EURO',
  retailAbove = false,
  retailValue,
  secondaryValue,
  secondaryUnit,
  tax,
  proLabel,
  placeholderDisabled = false,
}) => {
  const rootClassnames = classnames(
    styles.root,
    {
      [styles.retailAbove]: retailAbove,
      [styles.secondaryDisplayed]: secondaryValue || proLabel,
      [styles.onlyPro]: !secondaryValue && proLabel,
      [styles.placeholderDisabled]: placeholderDisabled,
    },
    className,
  );

  return (
    <div className={rootClassnames} data-testid="detailed-price">
      <Main
        className={styles.main}
        value={value}
        currency={currency}
        tax={tax}
        translations={translations?.price}
      />
      {secondaryValue && (
        <Secondary
          className={styles.secondary}
          value={secondaryValue}
          currency={currency}
          tax={tax !== undefined ? invertedTaxMap[tax] : undefined}
          translations={translations?.price}
          unit={secondaryUnit}
        />
      )}

      {proLabel && <Pro className={styles.pro} />}

      {retailValue !== undefined && retailValue > 0 && (
        <Retail
          className={styles.retail}
          value={retailValue}
          currency={currency}
          tax={tax}
          translations={translations?.price}
        />
      )}
    </div>
  );
};

DetailedPrice.displayName = 'DetailedPrice';
