import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import {
  PriceDeprecated,
  type PriceDeprecatedProps,
} from '@/core/tamagoshi/deprecated/PriceDeprecated/PriceDeprecated';

import styles from './Main.module.scss';

export const Main: FunctionComponent<PriceDeprecatedProps> = ({
  className,
  currency,
  ...otherProps
}) => {
  const rootClassnames = classnames(styles.root, className);

  return (
    <PriceDeprecated
      className={rootClassnames}
      currency={currency}
      data-testid="price-main"
      supDecimal={currency === 'EURO'}
      taxSmallSize={currency === 'POUND_STERLING'}
      {...otherProps}
    />
  );
};

Main.displayName = 'Main';
