const UNIT_LEN = 3;

export function applyThousandSeparator(
  aNumber: unknown,
  unitSeparator = ',',
): string | undefined {
  const numberStr = String(aNumber || '');
  const len = numberStr.length;

  if (len <= UNIT_LEN) {
    return numberStr;
  }

  let curIdx = len - UNIT_LEN;
  let curStr = '';

  while (curIdx > 0) {
    curStr = numberStr.slice(curIdx, curIdx + UNIT_LEN) + curStr;

    if (isNumber(Number(numberStr[curIdx - 1]))) {
      curStr = unitSeparator + curStr;
    }
    if (curIdx <= UNIT_LEN) {
      curStr = numberStr.slice(0, Math.max(0, curIdx)) + curStr;
      return curStr;
    }
    curIdx -= UNIT_LEN;
  }
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}
