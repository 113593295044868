import { Svg, type SvgProps } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import { type FunctionComponent } from 'react';
export const Pro: FunctionComponent<SvgProps> = props => <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 16" width={43} height={15} data-testid="mpro-logo" optimizationId="MPro" {...props}>
    <defs>
      <linearGradient id="linearGradient-1" x1="17.337%" x2="72.327%" y1="75.452%" y2="32.565%">
        <stop offset=".044%" stopColor="#E6685A" />
        <stop offset="100%" stopColor="#FFB950" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" transform="translate(-186 -843)">
        <g transform="translate(0 178)">
          <g transform="translate(16 663)">
            <g transform="translate(170 2)">
              <g>
                <path fill="url(#linearGradient-1)" d="M16.748 5.972L12.298.54a1.443 1.443 0 00-1.326-.506L3.994 1.14c-.502.082-.92.413-1.108.89L.36 8.568a1.432 1.432 0 00.219 1.397l4.45 5.432c.324.393.825.579 1.327.507l6.977-1.107c.502-.083.92-.414 1.108-.89l2.528-6.539a1.382 1.382 0 00-.22-1.397z" />
                <path fill="#FFF" d="M10.5648115 4.80812081L8.99995088 7.6077792 7.45581696 4.29157867 5.52824032 4.79778996 3.14467787 10.9962956 5.26915746 10.9962956 6.70965824 7.33917728 8.11906912 10.8206713 9.20721717 10.8206713 11.3006068 7.09123707 11.3006068 11.8640864 13.28 11.1925816 13.28 5.29367041z" />
              </g>
              <g fill="#0C193A" transform="translate(20.2 3.267)">
                <path d="M7.003 3.125c0 1.098-.318 1.946-.954 2.544-.635.598-1.539.897-2.71.897h-.732v3.26H0V0h3.34c1.219 0 2.134.271 2.746.813.611.542.917 1.313.917 2.312zM2.52 4.49h.485c.4 0 .717-.114.952-.342.236-.229.354-.544.354-.946 0-.676-.368-1.015-1.104-1.015H2.52V4.49zM15.174 3.3c.773-.745 1.71-1.103 2.84-1.103s2.082.358 2.84 1.104C21.629 4.03 22 4.97 22 6.12c0 1.15-.387 2.09-1.145 2.806-.759.716-1.696 1.074-2.84 1.074-1.131 0-2.083-.358-2.841-1.074-.759-.732-1.13-1.672-1.13-2.806 0-1.149.371-2.089 1.13-2.82zm2.84 4.522c.833 0 1.517-.671 1.517-1.7 0-1.045-.669-1.732-1.517-1.732-.833 0-1.517.702-1.517 1.731.015 1.03.684 1.701 1.517 1.701zm-5.697-5.728c.223 0 .435.018.636.054l.144.026-.23 2.464c-.209-.054-.5-.08-.871-.08-.56 0-.966.13-1.22.39-.253.26-.38.635-.38 1.125V9.79H7.833V2.227h1.908l.4 1.208h.124c.214-.4.513-.724.895-.971.383-.247.768-.37 1.157-.37z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>;
Pro.displayName = 'Pro';