export const CURRENCIES = ['EURO', 'POUND_STERLING'] as const;

export type Currency = (typeof CURRENCIES)[number];
export type CurrencySymbol = '€' | '£';

export function getCurrencySymbol(currency: Currency): CurrencySymbol {
  switch (currency) {
    case 'EURO':
      return '€';

    case 'POUND_STERLING':
      return '£';

    default:
      return '€';
  }
}

const CURRENCY_ALIASES: Record<string, Currency> = {
  EUR: 'EURO',
};

export function parseCurrency(input: string): Currency | undefined {
  const upperCaseInput = input.toUpperCase();
  return (
    CURRENCIES.find((value) => value === upperCaseInput) ??
    CURRENCY_ALIASES[upperCaseInput]
  );
}
