export function extractValues(
  value: number | string,
  stripEmptyDecimalValue = true,
): { decimal: string | undefined; integer: string } {
  const [integer, decimal] = parseFloat(`${value}`.replace(',', '.'))
    .toFixed(2)
    .split('.');

  let decimalValue = 0;
  if (decimal) {
    decimalValue = parseInt(decimal, 10);
  }
  const isDecimalStartWithZero = !!decimal && decimal.startsWith('0');
  const alternateForEmtyDecimal = !stripEmptyDecimalValue ? '00' : undefined;

  return {
    decimal:
      decimalValue > 0
        ? `${isDecimalStartWithZero ? '0' : ''}${decimalValue}`
        : alternateForEmtyDecimal,
    integer,
  };
}
