import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import {
  PriceDeprecated,
  type PriceDeprecatedProps,
} from '@/core/tamagoshi/deprecated/PriceDeprecated/PriceDeprecated';

import styles from './Secondary.module.scss';

export const Secondary: FunctionComponent<PriceDeprecatedProps> = ({
  className,
  ...otherProps
}) => {
  const rootClassnames = classnames(styles.root, styles.secondary, className);

  return (
    <PriceDeprecated
      className={rootClassnames}
      data-testid="price-secondary"
      {...otherProps}
    />
  );
};

Secondary.displayName = 'Secondary';
