import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { FastDeliveryByMMLabel } from '@/core/tamagoshi/FastDeliveryByMMLabel/FastDeliveryByMMLabel';
import { FastDeliveryLabel } from '@/core/tamagoshi/FastDeliveryLabel/FastDeliveryLabel';
import { FreeDeliveryByMMLabel } from '@/core/tamagoshi/FreeDeliveryByMMLabel/FreeDeliveryByMMLabel';
import { FreeDeliveryLabel } from '@/core/tamagoshi/FreeDeliveryLabel/FreeDeliveryLabel';
import { FreeFastDeliveryByMMLabel } from '@/core/tamagoshi/FreeFastDeliveryByMMLabel/FreeFastDeliveryByMMLabel';
import { ManoExpressMediumLogo } from '@/core/tamagoshi/Logos/ManoExpressMediumLogo/ManoExpressMediumLogo';
import { LocalizedFreeManoExpressDeliveryLogo } from '@/domains/productDelivery/components/LocalizedFreeManoExpressDeliveryLogo/LocalizedFreeManoExpressDeliveryLogo.default';

import { type ProductCardTranslations } from '../../interfaces';

import styles from './DeliveryLabel.module.scss';

export type DeliveryLabelType =
  | 'freeDelivery'
  | 'fastDelivery'
  | 'freeDeliveryByMM'
  | 'fastDeliveryByMM'
  | 'freeFastDeliveryByMM'
  | 'freeManoExpressDelivery'
  | 'manoExpressDelivery';

export interface DeliveryLabelProps {
  className?: string;
  deliveryLabel?: DeliveryLabelType;
  translations: ProductCardTranslations['deliveryLabel'];
  isPro?: boolean;
}

export const DeliveryLabel: FunctionComponent<DeliveryLabelProps> = ({
  className,
  deliveryLabel,
  translations,
  isPro = false,
}) => {
  const rootClassNames = classnames(styles.root, className);

  switch (deliveryLabel) {
    case 'freeDelivery':
      return (
        <FreeDeliveryLabel
          label={translations.freeDelivery}
          className={rootClassNames}
        />
      );

    case 'fastDelivery':
      return (
        <FastDeliveryLabel
          label={translations.fastDelivery}
          className={rootClassNames}
        />
      );

    // TODO: Remove this case when the rebranding is done
    case 'freeDeliveryByMM':
      return (
        <FreeDeliveryByMMLabel
          isPro={isPro}
          label={translations.freeDeliveryByMM}
          className={rootClassNames}
        />
      );

    // TODO: Remove this case when the rebranding is done
    case 'fastDeliveryByMM':
      return (
        <FastDeliveryByMMLabel
          isPro={isPro}
          label={translations.fastDeliveryByMM}
          className={rootClassNames}
        />
      );

    // TODO: Remove this case when the rebranding is done
    case 'freeFastDeliveryByMM':
      return (
        <FreeFastDeliveryByMMLabel
          isPro={isPro}
          label={translations.freeFastDeliveryByMM}
          className={rootClassNames}
        />
      );

    // Mano Express rebranding
    case 'manoExpressDelivery':
      return <ManoExpressMediumLogo className={rootClassNames} />;

    case 'freeManoExpressDelivery':
      return (
        <LocalizedFreeManoExpressDeliveryLogo className={rootClassNames} />
      );

    default:
      return <span className={styles.emptySpan} />;
  }
};

DeliveryLabel.displayName = 'DeliveryLabel';
